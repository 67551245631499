// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as ResourceEditForm from "./ResourceEditForm.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceEditNavbar from "../resource-edit-navbar/ResourceEditNavbar.res.js";

function ResourceEdit(props) {
  var resource = props.resource;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ResourceEditNavbar.make, {
                      selectedCategory: "EditResource",
                      resourceId: resource.id
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: DashboardIndexCss.title,
                              children: "Edit Resource"
                            }),
                        JsxRuntime.jsx(ResourceEditForm.make, {
                              resource: resource,
                              categories: props.categories
                            })
                      ]
                    })
              ]
            });
}

var Css;

var make = ResourceEdit;

export {
  Css ,
  make ,
}
/* H1 Not a pure module */
